var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-container px-2" }, [
    _c(
      "div",
      { staticClass: "content-container" },
      [
        _c("h1", { staticClass: "mt-3" }, [_vm._v("Event Finished")]),
        _vm._v(" 1st Land Sale Lottery is finished."),
        _c("br"),
        _c("p", [
          _vm._v("You won "),
          _c("b", [_vm._v(_vm._s(_vm.wonAmount))]),
          _vm._v(" places on Land whitelist!"),
        ]),
        _c("span", [
          _vm._v("You can buy: "),
          _c("b", [_vm._v(_vm._s(_vm.toClaimAmount))]),
          _vm._v(" out of " + _vm._s(_vm.wonAmount) + " Lands"),
        ]),
        _c("br"),
        _c(
          "p",
          [
            _vm._v("Time left to buy Lands: "),
            _c(
              "v-chip",
              [
                _c("timer", {
                  attrs: { "deadline-timestamp": _vm.deadlineLandWhitelist },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c(
          "v-stepper",
          {
            attrs: { "non-linear": "" },
            model: {
              value: _vm.steps,
              callback: function ($$v) {
                _vm.steps = $$v
              },
              expression: "steps",
            },
          },
          [
            _c(
              "v-stepper-header",
              [
                _c(
                  "v-stepper-step",
                  {
                    attrs: {
                      editable: !_vm.isApproved,
                      complete: _vm.isApproved,
                      step: "1",
                    },
                  },
                  [_vm._v(" Approve SCRL ")]
                ),
                _c("v-divider"),
                _c("v-stepper-step", { attrs: { step: "2" } }, [
                  _vm._v(" Buy Land "),
                ]),
              ],
              1
            ),
            _c(
              "v-stepper-items",
              [
                _c(
                  "v-stepper-content",
                  { attrs: { step: "1" } },
                  [
                    _c(
                      "v-card",
                      { staticClass: "step-card" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-information-outline"),
                            ]),
                            _vm._v(
                              " All tokens used to buy Land during 1st sale are "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.coindesk.com/learn/what-does-it-mean-to-burn-crypto/",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("burned")]
                            ),
                            _vm._v(" decreasing total SCRL supply. "),
                            _c("br"),
                            _c("br"),
                            _c(
                              "span",
                              { staticClass: "text--primary card-text" },
                              [
                                _vm._v(
                                  " Approve contract to use your tokens to buy the Land "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "pull-right",
                                attrs: {
                                  large: "",
                                  disabled:
                                    _vm.isApproved || 0 === _vm.userClaimAmount,
                                  loading: _vm.loadingData,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.approve()
                                  },
                                },
                              },
                              [_vm._v(" Approve ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-stepper-content",
                  { attrs: { step: "2" } },
                  [
                    _c(
                      "v-card",
                      { staticClass: "step-card" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-information-outline"),
                            ]),
                            _vm._v(
                              " All tokens used to buy Land during 1st sale are "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.coindesk.com/learn/what-does-it-mean-to-burn-crypto/",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("burned")]
                            ),
                            _vm._v(" decreasing total SCRL supply "),
                            _c("br"),
                            _c("br"),
                            _c(
                              "span",
                              { staticClass: "text--primary card-text" },
                              [
                                _vm._v(
                                  " Choose amount of Lands you want to buy."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Each is for " +
                                    _vm._s(
                                      _vm._f("localFormatNumber")(100000)
                                    ) +
                                    " SCRL. "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-center flex-column" },
                          [
                            _c("input-amount", {
                              attrs: {
                                "init-value": _vm.userClaimAmount,
                                "max-value": _vm.toClaimAmount,
                                label: "Buy amount",
                              },
                              on: { valueChanged: _vm.valueChanged },
                            }),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  large: "",
                                  disabled:
                                    !_vm.isApproved ||
                                    _vm.toClaimAmount < 1 ||
                                    _vm.chosenMoreTokenThanAvailable(
                                      _vm.userClaimAmount
                                    ),
                                  loading: _vm.loadingData,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.mintAllLands()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " 2. Buy Lands (" +
                                    _vm._s(_vm.realBatchAmount) +
                                    "/" +
                                    _vm._s(_vm.toClaimAmount) +
                                    ") "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "dialog-box",
          {
            attrs: {
              title: "Land claimed",
              width: "500",
              model: _vm.claimedWinnerDialog,
            },
            on: {
              dialogClosed: function ($event) {
                _vm.claimedWinnerDialog = false
              },
            },
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-text",
                  { staticClass: "mdi-format-align-middle text-center" },
                  [
                    _c("img", {
                      staticStyle: { height: "150px" },
                      attrs: { src: "/img/portal/default_250.png" },
                    }),
                    _c("br"),
                    _c("h1", [_vm._v("Congratulations!")]),
                    _c("br"),
                    _c("p", [
                      _vm._v(" You won a new Land."),
                      _c("br"),
                      _vm._v(' It will appear soon on the My Lands" tab '),
                    ]),
                  ]
                ),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-btn",
                      { attrs: { to: { name: "MyLands", params: {} } } },
                      [_vm._v("Go to My Lands")]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        on: {
                          click: function ($event) {
                            _vm.claimedWinnerDialog = false
                          },
                        },
                      },
                      [_vm._v("Close")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "lock-container rounded" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="main-container px-2">
        <div class="content-container">
            <h1 class="mt-3">Event Finished</h1>
            1st Land Sale Lottery is finished.<br/>

            <p>You won <b>{{ wonAmount }}</b> places on Land whitelist!</p>
            <span>You can buy: <b>{{ toClaimAmount }}</b> out of {{ wonAmount }} Lands</span>
            <br/>
            <p>Time left to buy Lands:
                <v-chip>
                    <timer :deadline-timestamp="deadlineLandWhitelist"></timer>
                </v-chip>
            </p>
            <br/>

            <v-stepper v-model="steps" non-linear>
                <v-stepper-header>
                    <v-stepper-step
                        :editable="!isApproved"
                        :complete="isApproved"
                        step="1"
                    >
                        Approve SCRL
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        step="2"
                    >
                        Buy Land
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card class="step-card">
                            <v-card-text>
                                <v-icon small>mdi-information-outline</v-icon>
                                All tokens used to buy Land during 1st sale are
                                <a href="https://www.coindesk.com/learn/what-does-it-mean-to-burn-crypto/"
                                   target="_blank">burned</a>
                                decreasing total SCRL supply.
                                <br/><br/>
                                <span class="text--primary card-text">
                                Approve contract to use your tokens to buy the Land
                            </span>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    large
                                    class="pull-right"
                                    @click="approve();"
                                    v-bind:disabled="isApproved || 0 === userClaimAmount"
                                    v-bind:loading="loadingData"
                                >
                                    Approve
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card class="step-card">
                            <v-card-text>
                                <v-icon small>mdi-information-outline</v-icon>
                                All tokens used to buy Land during 1st sale are
                                <a href="https://www.coindesk.com/learn/what-does-it-mean-to-burn-crypto/"
                                   target="_blank">burned</a>
                                decreasing total SCRL supply
                                <br/><br/>
                                <span class="text--primary card-text">
                                Choose amount of Lands you want to buy.<br/>
                                Each is for {{ 100000 | localFormatNumber }} SCRL.
                            </span>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-center flex-column">
                                <input-amount
                                    :init-value="userClaimAmount"
                                    :max-value="toClaimAmount"
                                    v-on:valueChanged="valueChanged"
                                    label="Buy amount"
                                ></input-amount>
                                <v-btn
                                    large
                                    class="ml-2"
                                    @click="mintAllLands()"
                                    v-bind:disabled="!isApproved || toClaimAmount < 1 || chosenMoreTokenThanAvailable(userClaimAmount)"
                                    v-bind:loading="loadingData"
                                >
                                    2. Buy Lands ({{ realBatchAmount }}/{{ toClaimAmount }})
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>


            <dialog-box
                title="Land claimed"
                width="500"
                v-bind:model="claimedWinnerDialog"
                v-on:dialogClosed="claimedWinnerDialog=false"
            >
                <v-card>
                    <v-card-text class="mdi-format-align-middle text-center">
                        <img src="/img/portal/default_250.png" style="height:150px"/><br/>
                        <h1>Congratulations!</h1>
                        <br/>
                        <p>
                            You won a new Land.<br/>
                            It will appear soon on the My Lands" tab
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn :to="{ name: 'MyLands', params: {}}">Go to My Lands</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="claimedWinnerDialog=false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </dialog-box>

        </div>
        <div class="lock-container rounded"/>
    </div>
</template>

<script>
import DialogBox from "./dialog-box";
import InputAmount from "./input-amount";
import Timer from "./timer";
import {LandLotteryWhitelistContract} from "../services/contracts/landLotteryContract";

export default {
    components: {Timer, InputAmount, DialogBox},
    props: ['stakesAmount'],
    data() {
        return {
            landLotteryWhitelistContract: null,
            deadlineLandWhitelist: 1654144856,
            maxBatchBuyAmount: 25, //TODO: test with bigger amounts
            steps: 1,
            allowance: 0,
            loadingData: true,
            singleStakeTokenAmount: 100000 * (10 ** 18),
            wonAmount: null,
            claimedAmount: null,
            userClaimAmount: null,
            claimedWinnerDialog: false,
        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.fetchData();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.fetchData();
            });
        }
    },
    computed: {
        web3Balance() {
            return this.web3.balance;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        toClaimAmount() {
            return this.wonAmount - this.claimedAmount;
        },
        isApproved() {
            return this.allowance > 0 && this.allowance >= (this.userClaimAmount * this.singleStakeTokenAmount);
        },
        realBatchAmount() {
            return Math.min(this.maxBatchBuyAmount, this.userClaimAmount);
        },
    },
    methods: {
        chosenMoreTokenThanAvailable(tokensAmount) {
            return this.stakeTokenAmount(tokensAmount) > this.web3Balance;
        },
        stakeTokenAmount(stakes) {
            return stakes * this.singleStakeTokenAmount;
        },
        fetchData() {
            this.landLotteryWhitelistContract = new LandLotteryWhitelistContract(this.web3);
            this.fetchAllowance();

            this.landLotteryWhitelistContract.winnerOfMyLotteryWhitelist().then((res) => {
                this.wonAmount = res.wonAmount;
                this.claimedAmount = res.claimedAmount;
                this.userClaimAmount = this.toClaimAmount;
                this.loadingData = false;
            });
        },
        fetchAllowance() {
            this.landLotteryWhitelistContract.tokenAllowanceForLotteryWhitelist().then((allowance) => {
                this.allowance = allowance;
                if (this.isApproved) {
                    this.steps = 2;
                }
            });
        },
        approve() {
            this.loadingData = true;
            this.landLotteryWhitelistContract.approveForLotteryStakeWhitelist()
                .then(() => {
                    this.fetchAllowance();
                    this.steps = 2;
                })
                .catch((error) => {
                    console.error('LandLotteryWhitelist-approve', error);
                    alert('An error occurred. Please try again later or contact Wizarre support.');
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },
        mintSingleLand() {
            this.loadingData = true;
            this.landLotteryWhitelistContract.mintSingleLandByWinner()
                .then(() => {
                    this.claimedWinnerDialog = true;
                })
                .catch((error) => {
                    console.error('LandLotteryWhitelist-mintSingleLand', error);
                    alert('An error occurred. Please try again later or contact Wizarre support.');
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },
        mintAllLands() {
            this.loadingData = true;
            this.landLotteryWhitelistContract.mintAllLandByWinner(this.realBatchAmount)
                .then(() => {
                    this.claimedWinnerDialog = true;
                    this.fetchData();
                })
                .catch((error) => {
                    console.error('LandLotteryWhitelist-mintAllLands', error);
                    alert('An error occurred. Please try again later or contact Wizarre support.');
                })
                .finally(() => {
                    this.loadingData = false;
                })
        },
        valueChanged(newValue) {
            this.userClaimAmount = newValue.value;
        },
    }
};
</script>

<style lang="scss" scoped>
a {
    color: #fff;
    text-decoration: underline;
}

.step-card {
    min-height: 100px;
}

.main-container {
    position: relative;
}

.content-container {
    position: relative;
}

.lock-container {
    background-color: rgb(241, 234, 234, 0.25);
    background-image: url("/img/summoning/png/padlock@72x.png");
    background-position: center;
    background-size: 115%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
}
</style>
